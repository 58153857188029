<template>
  <div class="wrapper static-article">
    <row>
      <column
        xs="12"
        md="2">
      </column>
      <column
        xs="12"
        md="8">
        <h1 class="ff-flama fs-xxl fw-light tt-uppercase mb-5">
          Om IntraFish
        </h1>
        <div class="article-body clearfix mb-3">
          <p>
            IntraFish er verdens største leverandør av nyheter og informasjon om sjømatnæringen, og har i mer enn 20 år inspirert sine lesere med betydningsfull innsikt. IntraFish har hovedkontor i Bergen og kontorer i flere byer i Norge, samt Seattle, Puerto Varas, Singapore, og London.
          </p>
          <p>
            IntraFish er en del av <a href="https://www.nhst.no/forretningsomrader/#globalpublications">NHST Global Publications</a> som i Norge er ansvarlig utgiver av de ledende nettstedene <a href="/">IntraFish.no</a>, <a href="https://www.tekfisk.no/">Tekfisk.no</a> og <a href="https://www.fiskeribladet.no/">Fiskeribladet.no</a>, samt papiravisen Fiskeribladet. I tillegg har selskapet den globale nyhetsportalen <a href="https://www.intrafish.com/">IntraFish.com</a>, som inkluderer IntraFish Aquaculture, IntraFish Fisheries, IntraFish Markets og IntraFish Processing. IntraFish publiserer også egne <a href="/industrirapporter">industrirapporter</a> om et bredt spekter av emner.
          </p>
          <p>
            IntraFish arrangerer Seafood Investor Forums to ganger årlig, samt Leadership Luncheons under sjømatmessen i Brussel og AquaNor/Nor-Fishing.
          </p>
          <p>
            NHST Global Publications er en del av <a href="https://www.dngroup.com/">DN Media Group</a>, et av Skandinavias største medieselskaper, med over 1,3 milliarder kroner i årlige inntekter. Konsernet publiserer blant annet <a href="https://www.dn.no/">Dagens Næringsliv</a> og flere finanspublikasjoner for sektorene <a href="https://www.tradewindsnews.com/">shipping</a>, <a href="https://www.upstreamonline.com/">olje & gass</a> og <a href="https://www.rechargenews.com/">fornybar energi</a>.
          </p>
        </div>
      </column>
    </row>
  </div>
</template>

<script>
import { Page } from 'global-components';
export default {
  name: 'aboutus-page',
  extends: Page
};
</script>
